<template>
  <page>
    <template slot="headerRight">
      <el-button v-auth="'brand:import'" @click="visible = true" type="primary">
        导入
      </el-button>
      <el-button
        v-auth="'brand:add'"
        @click="
          addVisible = true
          currentItem = null
        "
        type="primary"
      >
        添加品牌
      </el-button>
      <!-- <el-button @click="delHandler(false)" :disabled="!multipleSelection.length" type="danger"> 批量删除 </el-button> -->
    </template>

    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="品牌名称" prop="brandName">
          <el-input
            v-model="params.brandName"
            placeholder="品牌名称"
          ></el-input>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        v-loading="loading"
        height="100%"
      >
        <!-- <el-table-column  type="selection" width="55" /> -->
        <el-table-column show-overflow-tooltip prop="id" label="品牌ID" />
        <el-table-column
          show-overflow-tooltip
          prop="brandName"
          label="品牌名称"
        />
        <el-table-column
          show-overflow-tooltip
          prop="createTime"
          label="创建时间"
        />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'brand:update'"
              @click="editHandler(row)"
              type="text"
              size="mini"
              >编辑</el-button
            >
            <el-button
              v-auth="'brand:delete'"
              @click="delHandler(row.id)"
              type="text"
              size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <ImportModal
      v-if="visible"
      @getData="getData(true)"
      :visible.sync="visible"
      :current-item="currentItem"
    />

    <AddModal
      v-if="addVisible"
      @getData="getData(true)"
      :visible.sync="addVisible"
      :current-item="currentItem"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import ImportModal from './components/ImportModal.vue'
import AddModal from './components/AddModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      visible: false,
      addVisible: false,
      currentItem: null,
      tableData: [],
      brandList: [],
      stateList: [
        {
          label: '正常',
          value: 1
        },
        {
          label: '待上架',
          value: 3
        }
      ],
      typeList1: [],
      typeList2: [],
      distributionList: [
        {
          label: '支持',
          value: '0'
        },
        {
          label: '不支持',
          value: '1'
        }
      ],
      multipleSelection: [],
      params: {
        limit: 20,
        page: 1,
        brandName: ''
      },
      totalCount: 0
    }
  },
  created() {
    this.getData()
  },
  components: {
    ImportModal,
    AddModal
  },
  methods: {
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        const res = await this.$api.brandList.getGoodsBrandList(params)
        if (res.brandList && res.brandList.list) {
          this.tableData = res.brandList.list
        }

        this.totalCount = res.brandList.total
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(_ => _.id)
    },

    async delHandler(id) {
      try {
        await this.$confirm(
          id ? '是否要删除该品牌？' : '是否要批量删除所选品牌？',
          '温馨提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        )
        try {
          this.loading = true

          await this.$api.brandList.delProduct({
            id: id
          })
          this.$message.success('删除成功')
          this.getData()
        } catch (e) {
          console.log(e)
        } finally {
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      }
    },

    async editHandler(row) {
      try {
        this.loading = true

        this.addVisible = true
        this.currentItem = row
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
