<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="!isCreated ? '编辑' : '新增'"
    :visible.sync="show"
    width="500px"
  >
    <div class="content">
      <el-form
        label-width="100px"
        :model="form"
        size="mini"
        :rules="rules"
        ref="ruleForm"
      >
        <div class="row">
          <el-form-item label="品牌名称" prop="brandName">
            <el-input v-model="form.brandName" placeholder="请输入品牌名称" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
      <el-button type="primary" :loading="loading" @click="submit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      rules: {
        brandName: [{ required: true, message: '请输入品牌名称' }]
      },
      form: {
        brandName: ''
      },
      loading: false
    }
  },
  created() {
    if (this.currentItem) {
      this.form.brandName = this.currentItem.brandName
    }
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            const params = { ...this.form }

            if (!this.isCreated) {
              params.id = this.currentItem.id
              await this.$api.brandList.updateGoodsBrandInfo(params)
              this.$message.success('编辑成功')
            } else {
              await this.$api.brandList.saveGoodsBrand(params)
              this.$message.success('添加成功')
            }
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.row {
  display: flex;
  .left,
  .right {
    flex: 1;
  }
  .right {
    margin-left: 40px;
  }
}
</style>
